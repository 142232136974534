<template>
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-12">
					<a-tabs @change="onChangeTab" :activeKey="activeTab" :defaultActiveKey="activeTab">
						<a-tab-pane v-for="element in actualSubmenus" :tab="element.title" :key="element.key" force-render>
							<div v-if="element.key == 'purchase-orders_purchase-orders'">
								<purchaseOrders />
							</div>
							<div v-if="element.key == 'purchase-orders_bankingInstitutions'">
								<bankingInstitutions />
							</div>
						</a-tab-pane>
					</a-tabs>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import purchaseOrders from '@/views/purchase-orders'
import bankingInstitutions from '@/views/banking-institutions'
import utilities from '@/services/utilities'

export default {
	name: 'EgresosView',
	components: {
		purchaseOrders,
		bankingInstitutions,
	},
	computed: {
		...mapState(['settings']),
		actualSubmenus() {
			if (utilities.objectValidate(this.settings, 'menu', false)) {
				let index = this.settings.menu.findIndex((e) => e.url == this.$route.path)
				this.onChangeTab(this.settings.menu[index].submenus[0].key)
				return this.settings.menu[index].submenus
			} else {
				return []
			}
		},
	},
	data() {
		return {
			activeTab: '',
		}
	},
	methods: {
		onChangeTab(key) {
			this.activeTab = key
		},
	},
}
</script>